import React from 'react';
import { View } from 'react-native';
import { usePage } from '../../Contexts/page.context';
import { useStyle } from '../../Contexts/style.context';
import Character from '../../Pages/Character/character';
import Creation from '../../Pages/Creation/Creation';
import CreationCharacter from '../../Pages/Creation/CreationCharacter';
import Library from '../../Pages/Library/library';
import Profile from '../../Pages/Profile/profile';
import World from '../../Pages/World/world';
import MenuButton from '../Button/menuButton';
import Parchment from '../parchment/parchment';
import { NavigationPage } from './navigationPage';


export default function Navigation(props) {
    const { page, pageOption } = usePage();
    const { isMobile } = useStyle();

    return (
        <>
            <Parchment height={100} width={isMobile ? 90 : 90}>
                {page === "Menu" && <NavigationPage />}
                {page === "Profil" && <Profile />}
                {page === "Monde" && <World />}
                {page === "Bibliothèque" && <Library />}
                {page === "Personnage" && <Character />}
                {(page === "Création" && pageOption) && (pageOption === "Personnage" ? <CreationCharacter /> : <Creation name={pageOption} />)}
            </Parchment>
            {/* <MenuButton /> */}
        </>
    )
}
