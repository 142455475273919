import { useState, useEffect } from 'react';
import { Dimensions } from "react-native";

export default function useDimensions() {
  const windowMobile = Dimensions.get("window");
  const [dimensions, setDimensions] = useState({width: windowMobile.width, height: windowMobile.height});

  useEffect(() => {
    if (dimensions.width >= 450) {
      const handleResize = () => setDimensions({ width: window.innerWidth, height: window.innerHeight });
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    } else {
      const subscription = Dimensions.addEventListener("change", () => setDimensions({ width: window.width, height: window.height }));
      return () => subscription?.remove();
    }
  }, []);

  return dimensions;
}