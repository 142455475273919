import React, { createContext, useState } from "react";
const PageContext = createContext({});
export const PageProvider = props => {
    const [page, setPage] = useState();
    const [pageOption, setPageOption] = useState();
    const [lastPage, setLastPage] = useState();
    const [showParchment, setShowParchment] = useState(true);

    const submitPage = (value) => (
        setPage(value), setLastPage(value === "Menu" ? lastPage : value),
        setShowParchment(false), setTimeout(() => setShowParchment(true), 750)
    );
    const submitPageOption = (value) => (setPage("Création"), setPageOption(value),setLastPage("Création"))
    const pageContextValue = { page, lastPage, submitPage, pageOption, submitPageOption, showParchment, setShowParchment }
    return <PageContext.Provider value={pageContextValue} {...props} />
};
export const usePage = () => React.useContext(PageContext);