// LIBRARY
import { View, Text, ScrollView, TouchableOpacity } from 'react-native'
import React, { useState } from 'react'
// STYLE
// PAGE
// COMPONENT
// SERVICE
import { transformObjectToData } from '../../Services/TransformationData/transform';
// OTHER
import { useAuth } from '../../Contexts/auth.context';
import LibraryDetail from './libraryDetail';
import { useStyle } from '../../Contexts/style.context';

export default function Library() {
  const { userData } = useAuth();
  const {isMobile, styles } = useStyle();
  const [selected, setSelected] = useState()
  const worldData = transformObjectToData(userData.Monde[0]);
  const worldDataLibrary = []
  {
    worldData.map(e => {
      let key = e.key;
      switch (key) {
        case "ArkAttribut":
          key = "Ark Attribut";
          break;
        case "ArkCatégorie":
          key = "Ark Catégorie";
          break;
        case "Sexe":
          key = "Genre";
          break;
        case "Catégorie-item":
          key = "Item (catégorie)";
          break;
        case "Sous-catégorie-item":
          key = "Item (sous-catégorie)";
          break;

        default:
          break;
      }
      if (key !== "_id" && key !== "__v" && key !== "Nom" && key !== "Dé" && key !== "Description" && key !== "Créateur")
        worldDataLibrary.push({ key: key, value: e.value })
    })
  }
  return (
    <View style={styles.container}>
      <View style={[styles.container, styles.acenter]}>
        <Text style={[styles.title, styles.titleShadow]}>{selected ? selected.key : "Bibliothèque"}</Text>
      </View>
      {!selected &&
        <ScrollView style={{height: isMobile ? "90%" : "35vh", overflow: "scroll"}}>
          {worldDataLibrary.sort((a, b) => a.key > b.key ? 1 : -1).map(e => {
            return (
              <TouchableOpacity key={`library-list-${e.key}`} style={styles.libraryButton} onPress={() => setSelected(e)}>
                <Text style={[styles.text,{marginBottom: 5}]}> {`-> ${e.key}`}</Text>
              </TouchableOpacity>
            )
          })}
        </ScrollView>
      }
      {selected &&
        <>
          <ScrollView style={[styles.scrollView, {height: isMobile ? "75%" : "35vh"}]}>
            <LibraryDetail styles={styles} selected={selected} />
          </ScrollView>
          <TouchableOpacity style={[styles.rowContainer, styles.jcenter, { marginTop: 20 }]} onPress={() => setSelected(undefined)}>
            <Text style={[styles.text, styles.textBold]}>Revenir à la Bibliothèque</Text>
          </TouchableOpacity>
        </>
      }
    </View>
  )
}