import React from "react";
import { AuthProvider } from "./auth.context";
import { PageProvider } from "./page.context";
import { StyleProvider } from "./style.context";


export const ContextProvider = props => {
    return (
            <PageProvider>
                <AuthProvider>
                    <StyleProvider>
                        {props.children}
                    </StyleProvider>
                </AuthProvider>
            </PageProvider>
    )
};
