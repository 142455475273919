import { View, Text } from 'react-native'
import React from 'react'
import { styles } from '../../assets/styles/style'


export default function LibraryDetail({selected,styles}) {

  const dataField = (data,field,dataField) => (data?.[dataField] && <Text style={styles.text}>{field} : {data[dataField]}</Text>);
  let selectedValue; 
  selected.value.map(el => {
    const {Créateur, Monde, ...rest} = el
    selectedValue = rest;
  });

  return ( (selected.value.length > 0) ?
  selected.value.map(el => {

          return (
              <View key={`libraryDetail-list-${el.Nom}`} style={{paddingVertical: 10, borderBottomColor: "gray", borderBottomWidth: 1}}>
                  {dataField(el,"Nom","Nom")}
                  {/* {dataField(el,"Race","Race")} */}
                  {dataField(el,"Description","Description")}
                  {/* {selected.key === "Espèce" && <
                      Text style={styles.text2}>Race: <Text style={styles.text}>{el.Race.Nom}</Text></Text>
                  } */}
              </View>
          )
      })
      : <Text style={styles.text}>...Aucune création actuellement</Text>
  )
}
