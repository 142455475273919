// LIBRARY
import React, { useEffect, useState } from 'react';
import { Text, View, ScrollView, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
// STYLE
import { styles } from '../../assets/styles/style';
// PAGE
// COMPONENT
// SERVICE
// OTHER
import { useAuth } from '../../Contexts/auth.context';

export default function CharacterDetail({ data, option }) {
    const [fightOption, setFightOption] = useState("stats")
    const stats = data?.Stats[0];
    const substats = data?.["Sous-stats"][0];

    const DescriptionInformation = () => {
        return (
            <>
                <Text style={styles.text}>Nom: <Text style={styles.text2}>{data?.Nom ? data.Nom : "inconnu"}</Text></Text>
                <Text style={styles.text}>Prénom: <Text style={styles.text2}>{data?.Prénom ? data.Prénom : "inconnu"}</Text></Text>
                <Text style={styles.text}>Age: <Text style={styles.text2}>{data?.Age ? data.Age : "inconnu"}</Text></Text>
                <Text style={styles.text}>Race:
                    <Text style={styles.text2}>{data?.Race ? data.Race.Nom : "inconnu"} {data?.Espèce && `( ${data.Espèce.Nom} )`}</Text>
                </Text>
                <Text style={styles.text}>Classe: <Text style={styles.text2}>{data?.Classe ? data.Classe.Nom : "aucune"}</Text></Text>
                <Text style={styles.text}>Titre:
                    <Text style={styles.text2}>
                        {data?.Titre.length !== 0 ? data.Titre.map(item => item.Nom) : " aucun"}
                    </Text>
                </Text>
                <Text style={styles.text}>Don: 
                    <Text style={styles.text2}>
                         {data?.Don.length !== 0 ? data.Don.map(item => item.Nom) : " aucun"}
                    </Text>
                </Text>
                <Text style={styles.text}>Malédiction:
                    <Text style={styles.text2}>
                        {data?.Malédiction.length !== 0 ? data.Malédiction.map(item => item.Nom) : " aucune"}
                    </Text>
                </Text>
                <Text style={styles.text}>Description: <Text style={styles.text2}>{data?.Description ? data.Description : ". . ."}</Text></Text>
                <Text style={styles.text}>Argent: <Text style={styles.text2}>{data?.Argent && `${data.Argent} arkion`}</Text></Text>
            </>
        )
    }
    const StatsInformation = () => {
        return (
            <>
                <Text style={[styles.text2, {marginVertical: 15, textDecorationLine: "underline"}]}>Stats</Text>
                <Text style={styles.text}>Force: <Text style={styles.text2}>{stats.Force}</Text></Text>
                <Text style={styles.text}>Résistance: <Text style={styles.text2}>{stats.Résistance}</Text></Text>
                <Text style={styles.text}>Constitution: <Text style={styles.text2}>{stats.Constitution}</Text></Text>
                <Text style={styles.text}>Vitesse: <Text style={styles.text2}>{stats.Vitesse}</Text></Text>
                <Text style={styles.text}>Prestance: <Text style={styles.text2}>{stats.Prestance}</Text></Text>
                <Text style={styles.text}>Sagesse: <Text style={styles.text2}>{stats.Sagesse}</Text></Text>
                <Text style={styles.text}>Résistance Magique: <Text style={styles.text2}>{stats["Résistance Magique"]}</Text></Text>
                <Text style={styles.text}>Volonté: <Text style={styles.text2}>{stats.Volonté}</Text></Text>
                <Text style={[styles.text2, {marginVertical: 15, textDecorationLine: "underline"}]}>Sous-Stats</Text>
                <Text style={styles.text}>Chance: <Text style={styles.text2}>{substats.Chance}</Text></Text>
                <Text style={styles.text}>Discrétion: <Text style={styles.text2}>{substats.Discrétion}</Text></Text>
                <Text style={styles.text}>Perception: <Text style={styles.text2}>{substats.Perception}</Text></Text>
                <Text style={styles.text}>Réflexe: <Text style={styles.text2}>{substats.Réflexe}</Text></Text>
                <Text style={styles.text}>Précision: <Text style={styles.text2}>{substats.Précision}</Text></Text>
                <Text style={styles.text}>Perception Magique: <Text style={styles.text2}>{substats["Perception Magique"]}</Text></Text>
            </>
        )
    }
    const AbilitiesInformation = () => {
        return (
            <>
                <Text style={styles.text2}>Arkence</Text>
                {data.Arkence.map(arkence =>
                    <Text key={`ark-${arkence._id}`} style={styles.text}>
                        {arkence.Nom}
                    </Text>
                )}
                <Text style={styles.text2}>Arkane</Text>
                {data.Arkane.map(arkane =>
                    <Text key={`ark-${arkane._id}`} style={styles.text}>
                        {arkane.Nom}
                    </Text>
                )}
            </>
        )
    }


    return (
        <View style={styles.subContainer}>
            {option === "description" && DescriptionInformation()}
            {option === "fight" &&
                <>
                    <View style={styles.characterSubdivisionContainer}>
                        <Text
                            style={styles[fightOption === "stats" ? "textCharacterTitle" : "textCharacterTitleDisable"]}
                            onPress={() => setFightOption("stats")}>
                            Stats
                        </Text>
                        <Text style={styles.textCharacterTitleDisable}> / </Text>
                        <Text
                            style={styles[fightOption === "abilities" ? "textCharacterTitle" : "textCharacterTitleDisable"]}
                            onPress={() => setFightOption("abilities")}>
                            Capacité
                        </Text>
                    </View>
                    {fightOption === "stats" && StatsInformation()}
                    {fightOption === "abilities" && AbilitiesInformation()}
                </>
            }
        </View>
    )
}
