import { View, StyleSheet, ImageBackground, TouchableOpacity, Image, Text, } from 'react-native';
import Animated, { useSharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated';
import React, { useEffect, useRef, useState } from 'react';
import background from '../assets/images/background.jpg';
import parchment from '../assets/images/parchment.jpg';
import calc from '../assets/images/calc-parchment.png';


const Tablet = ({ children }) => {
    const [showParchment, setShowParchment] = useState(true);

    const animation = useSharedValue({ height: 90 });
    const animationStyle = useAnimatedStyle(() => {
        return {
            height: withTiming(`${animation.value.height}%`, { duration: 1000 })
        }
    })
    
    const closeParchment = () => {
        // setShowParchment(false);
        animation.value = { height: 0 }
        setTimeout(() => animation.value = { height: `${animation.value.height}%` }, 1500);
    }

    const Background = ({ children }) => {
        return (
            <ImageBackground source={background} style={styles.container}>
                {children}
            </ImageBackground>
        )
    }
    const Border = () => <View style={styles.borderParchment} />

    const Parchment = () => {
        return (
            <Animated.View style={[{ position: "relative", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", }, animationStyle]}>
                <Border />
                <Image source={parchment} style={[styles.parchment, { height: `90%` }]} />
                {showParchment && <>
                    <Image source={calc} style={[styles.calcParchement, styles.calcUp]} />
                    <Image source={calc} style={[styles.calcParchement, styles.calcDown]} />
                    <View style={{ position: "absolute", height: "90%", width: "90%", }}>
                        <TouchableOpacity onPress={() => closeParchment()}>
                            <Text>Activation animation</Text>
                        </TouchableOpacity>
                    </View>
                </>}
                <Border />
            </Animated.View >
        )
    }
    return (
        <Background>
            <Parchment />
        </Background>
    )

}
export default Tablet;

const styles = StyleSheet.create({
    container: {
        position: "relative", height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center",
    },
    borderParchment: {
        height: 10,
        width: "95%",
        borderWidth: 0.1,
        borderRadius: 5,
        backgroundColor: "#D3913A",
        borderColor: "rgba(0,0,0,0.9)",
        shadowColor: '#000',
        shadowOpacity: 0.5,
        shadowRadius: 5,
        shadowOffset: { width: 0, height: 8 }
    },
    parchment: {
        width: "90%"
    },
    calcParchement: {
        height: "20%", width: "90%", position: "absolute",
    },
    calcUp: {
        top: "5%"
    },
    calcDown: {
        bottom: "5%", transform: [{ rotate: '180deg' }],
    }
});