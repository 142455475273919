export const transformObjectToData = (object) => {
    const objectArr = []; const key = Object.keys(object); const value = Object.values(object);
    for (let i = 0; i < key.length; i++) { objectArr.push({ key: key[i], value: value[i] }) }
    return objectArr
}

export const addDataToObject = (object, setObject, key, value) => {
    if (object?.[key]) {
        object[key] = value;
        setObject(object)
    } else {
        setObject({ ...object, [key]: value })
    }
}

export const addMultipeObjectArrayToObject = (object, setObject, key, value, filterKey) => {
    let newValue = value;
    if(key === "Arkence" || key === "Arkane"){
        newValue = {
            Data: value,
            Nom: value.Nom,
        }
    }
    if(key === "Arkence"){
        newValue = {
            ...newValue,
            Maitrise: 5
        }
    }
    if( key === "Arkane"){
        newValue = {
            ...newValue,
            Lancé: 1,
            Dé: [value.Dé]
        }
    }
    if (object?.[key]) {
        const checkIfValue = object[key].findIndex(item => item[filterKey] === value[filterKey]);
        checkIfValue === -1 &&
            object[key].push(newValue)

        setObject(object)
    } else {
        setObject({ ...object, [key]: [newValue] })
    }
}

export const addSingleObjectArrayToObject = (parent, object, setObject, key, value) => {
    if (object?.[parent]) {
        const checkIfValue = object[parent].findIndex(item => item[key])
        checkIfValue === -1 ?
            object[parent][0] = { ...object[parent][0], [key]: value }
            : object[parent][0][key] = value;
        setObject(object)
    } else {
        setObject({ ...object, [parent]: [{ [key]: value }] })
    }
}