// LIBRARY
import { useEffect, useState } from 'react';
import { Text, View, ScrollView, TextInput, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
// STYLE
import { styles } from '../../assets/styles/style';
// PAGE
// COMPONENT
import popUp from '../../Components/Pop-up/popUp';
import { creationSelectBox, creationSelectBoxMultiple, creationSelectBoxOnSelect } from '../../Components/Box/selectBox';
// SERVICE
import { PostData } from '../../Services/Api/apiCall';
import { transformObjectToData,addDataToObject, addMultipeObjectArrayToObject, addSingleObjectArrayToObject } from '../../Services/TransformationData/transform';
import { checkOnlyNumber } from '../../Services/Verification/verification';
// OTHER
import { useAuth } from '../../Contexts/auth.context';


export default function CreationCharacter({ navigation }) {
  const { userData } = useAuth();
  const worldData = userData.Monde[0];
  const [token, setToken] = useState()
  const [step, setStep] = useState(1);
  const [showData, setShowData] = useState()
  const [showResponse, setShowResponse] = useState(false)
  const [dataResponse, setDataResponse] = useState()
  const [creationData, setCreationData] = useState()
  const body = {
    creation: creationData,
    Créateur: { id: userData._id, key: "characterCreation" },
    Monde: { id: worldData._id, key: "Habitant" }
  }

  useEffect(() => {
    const getToken = async () => { setToken(JSON.parse(await AsyncStorage.getItem("userToken"))) }
    getToken()
  }, [])

  const inputTextValue = (value) => creationData?.[value]
  const inputStatstValue = (value, key) => creationData?.[value]?.[0][key]

  const nextStep = () => { setStep(step + 1) };
  const previousStep = () => { setStep(step - 1) };


  // RENDER
  const generalInformation = () => {
    return (
      <View style={styles.sectionContainer}>
        <Text style={styles.sectiontTitle}>Informations Générale</Text>
        <View style={styles.subContainer}>
          <View style={styles.sectionInput}>
            <Text style={styles.text}>Nom :</Text>
            <TextInput style={styles.textInput} placeholder={""} placeholderTextColor={"white"} defaultValue={inputTextValue("Nom")} onChangeText={text => addDataToObject(creationData, setCreationData, "Nom", text)} />
          </View>
          <View style={styles.sectionInput}>
            <Text style={styles.text}>Prénom :</Text>
            <TextInput style={styles.textInput} placeholder={"Prénom du personnage"} placeholderTextColor={"white"} defaultValue={inputTextValue("Prénom")} onChangeText={text => addDataToObject(creationData, setCreationData, "Prénom", text)} />
          </View>
          <View style={styles.sectionInput}>
            <Text style={styles.text}>Age :</Text>
            <TextInput style={styles.textInput} placeholder={"Age du personnage"} placeholderTextColor={"white"} defaultValue={inputTextValue("Age")} onChangeText={text => addDataToObject(creationData, setCreationData, "Age", text)} />
          </View>
          {creationSelectBox(worldData, creationData, setCreationData, "Classe", "Nom", addDataToObject, showData, setShowData)}
          {creationSelectBox(worldData, creationData, setCreationData, "Race", "Nom", addDataToObject, showData, setShowData)}
          {(inputTextValue("Race") && inputTextValue("Race").Espèce.length !== 0) &&
            creationSelectBoxOnSelect(creationData.Race, creationData, setCreationData, "Espèce", "Espèce", "Nom", addDataToObject, showData, setShowData)
          }
          <View>
            <Text style={styles.text}>Description :</Text>
            <TextInput style={styles.textInputArea} placeholder={"Description du personnage"} placeholderTextColor={"white"} defaultValue={inputTextValue("Description")} onChangeText={text => addDataToObject(creationData, setCreationData, "Description", text)} multiline={true} />
          </View>
        </View>
      </View>
    )
  }

  const statsInformation = () => {
    return (
      <View style={styles.sectionContainer}>
        <Text style={styles.sectiontTitle}>Stats</Text>
        <View style={styles.subContainer}>
          {worldData.Stats.map(stats => {
            return (
              <View key={`${stats._id}`} style={styles.sectionInput}>
                <Text style={styles.text}>{`${stats.Nom}`}:</Text>
                <TextInput style={styles.textInput} defaultValue={inputStatstValue("Stats", `${stats.Nom}`)} keyboardType="number-pad" placeholder={"0"} placeholderTextColor={"white"} onChangeText={text => { if (checkOnlyNumber(text) === true) addSingleObjectArrayToObject("Stats", creationData, setCreationData, `${stats.Nom}`, text) }} />
              </View>
            )
          })}
        </View>
        <Text style={styles.sectiontTitle}>Sous-stats</Text>
        <View style={styles.subContainer}>
          {worldData["Sous-stats"].map(stats => {
            return (
              <View key={`${stats._id}`} style={styles.sectionInput}>
                <Text style={styles.text}>{`${stats.Nom}`}:</Text>
                <TextInput style={styles.textInput} defaultValue={inputStatstValue("Sous-stats", `${stats.Nom}`)} keyboardType="number-pad" placeholder={"0"} placeholderTextColor={"white"} onChangeText={text => { if (checkOnlyNumber(text) === true) addSingleObjectArrayToObject("Sous-stats", creationData, setCreationData, `${stats.Nom}`, text) }} />
              </View>
            )
          })}
        </View>
      </View>
    )
  }

  const fightInformation = () => {
    return (
      <View style={styles.sectionContainer}>
        <Text style={styles.sectiontTitle}>Informations liées au combat</Text>
        <View style={styles.subContainer}>
          {creationSelectBoxMultiple(worldData, creationData, setCreationData, "Arkence", "Nom", addMultipeObjectArrayToObject, showData, setShowData)}
          {creationSelectBoxMultiple(worldData, creationData, setCreationData, "Arkane", "Nom", addMultipeObjectArrayToObject, showData, setShowData)}
          {creationSelectBoxMultiple(worldData, creationData, setCreationData, "Item", "Nom", addMultipeObjectArrayToObject, showData, setShowData)}
        </View>
      </View>
    )
  }

  const recapInformation = () => {
    const dataArr = transformObjectToData(creationData)

    return (
      <View style={styles.sectionContainer}>
        <View style={styles.subContainer}>
          <Text style={styles.sectiontTitle}>Récapitulatif</Text>
          {dataArr.map(item => {
            if (item.key === "Nom" || item.key === "Prénom" || item.key === "Age" || item.key === "Description") {
              return <Text key={`dataArr-${item.key}`} style={styles.text}>{item.key} : <Text style={styles.text2}>{item.value}</Text></Text>
            }
            if (item.key === "Race" || item.key === "Classe" || item.key === "Espèce") {
              return <Text key={`dataArr-${item.value.Nom}`} style={styles.text}>{item.key} : <Text style={styles.text2}>{item.value.Nom}</Text></Text>
            }
            if (item.key === "Stats" || item.key === "Sous-stats") {
              const valueArr = transformObjectToData(item.value[0])
              return (
                <>
                  <Text key={`dataArr-${item.key}`} style={styles.text}>{item.key}</Text>
                  <View>
                    {valueArr.map(i => <Text key={`dataArr-${i.key}`} style={styles.text2}>{i.key} : {i.value}</Text>)}
                  </View>
                </>
              )
            }
            if (item.key === "Arkane" || item.key === "Arkence" || item.key === "Item") {
              return (
                <>
                  <Text key={`dataArr-${item?.key}`} style={styles.text}>{item?.key}</Text>
                  {item?.value.map(i => <Text style={styles.text2}>{i.Nom}</Text>)}
                </>
              )
            }
          })}
        </View>
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <View style={[styles.container, styles.acenter]}>
        <Text style={styles.title}>Création Personnage </Text>
      </View>
      <ScrollView style={styles.scrollView2}>
        {step === 1 && generalInformation()}
        {step === 2 && statsInformation()}
        {step === 3 && fightInformation()}
        {step === 4 && recapInformation()}
      </ScrollView>
      {step === 1 &&
        <View style={styles.buttonSectionSimple}>
          <TouchableOpacity style={styles.buttonNext} onPress={nextStep}>
            <Text style={styles.text}>Suivant</Text>
          </TouchableOpacity>
        </View>
      }
      <View style={styles.buttonSection}>
        {step > 1 &&
          <TouchableOpacity style={styles.buttonPrevious} onPress={previousStep}>
            <Text style={styles.text}>Précedent</Text>
          </TouchableOpacity>
        }
        {(step < 4 && step > 1) &&
          <TouchableOpacity style={styles.buttonNext} onPress={nextStep}>
            <Text style={styles.text}>Suivant</Text>
          </TouchableOpacity>
        }
        {step === 4 &&
          <TouchableOpacity style={styles.buttonSend} onPress={async () => {
            setShowResponse(true)
            setDataResponse(await PostData("creation", "character", token, body));
          }}>
            <Text style={styles.text2}>Créer</Text>
          </TouchableOpacity>
        }
      </View>
      {(showResponse && dataResponse) && popUp(`${dataResponse.Prénom} a été créer !`, setShowResponse)}

    </View>
  )
}