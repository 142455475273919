// LIBRARY
import { useEffect, useState } from 'react';
import { StyleSheet, Text, View, ScrollView, TouchableOpacity, Image } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
// STYLE
// PAGE
// COMPONENT
// SERVICE
import { GetData } from '../../Services/Api/apiCall';
// OTHER
import { useAuth } from '../../Contexts/auth.context';
import { transformObjectToData } from '../../Services/TransformationData/transform';
import { useStyle } from '../../Contexts/style.context';
import { getTimeBetween } from '../../Services/Verification/verification';
import { usePage } from '../../Contexts/page.context';
import MenuButton from '../../Components/Button/menuButton';

export default function Profile() {
  const { userData, setUserData } = useAuth();
  const { isMobile, styles } = useStyle();
  const { submitPage } = usePage();

  useEffect(() => {
    const getUserData = async () => {
      const user = await GetData("users", userData._id, JSON.parse(await AsyncStorage.getItem("userToken")))
      setUserData(user)
    }
    // getUserData()
  }, [])


  const dataArr = transformObjectToData(userData);
  const DataList = () => {
    return (
      dataArr.sort((a, b) => a.key > b.key ? 1 : -1).map(item => {
        if (item.key !== "_id" && item.key !== "__v" && item.key !== "username" && item.key !== "Création" && item.key !== "Connexion")
          return (
            <Text key={`profil-${item.key}`} style={[styles.text, { margin: isMobile ? 2 : "10 5" }]}>
              {`${item.key.split('-item').join(" d'item")} : ${item.value.length}`}
            </Text>
          )
      }))
  };

  const ProfilName = () => {
    return (
      <View style={[styles.container, styles.acenter, { height: "10%" }]}>
        <Text style={[styles.title, styles.titleShadow]}>{userData.username}</Text>
      </View>
    )
  };

  const UserInformation = () => {
    return (
      <View>
        {userData.profilPicture &&
          <View style={{ display: "flex", alignItems: "center" }}>
            <View style={{ height: 220, width: 220, borderRadius: 110, backgroundColor: "gray" }} />
          </View>
        }
        <Text style={styles.title2}>Mes informations</Text>
        <View>
          <Text style={styles.text}>Création du compte : {userData.Création}</Text>
          <Text style={styles.text}>Dernière Connexion : il y a {getTimeBetween(isMobile, userData.Connexion.time)}</Text>
          <View style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
            <Text style={styles.text}>Mes mondes : {userData.Monde.length} </Text>
            <TouchableOpacity onPress={() => submitPage("Monde")} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
              <Text style={styles.text}>(aller à la liste)</Text>
            </TouchableOpacity>
          </View>
          <View style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
            <Text style={styles.text}>Mes personnages : {userData.Personnage.length}</Text>
            <TouchableOpacity onPress={() => submitPage("Personnage")} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
              <Text style={styles.text}>(aller à la liste)</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  };

  const CreationInformation = () => {
    return (
      <View style={{ paddingBottom: 20, width: isMobile ? "100%" : "60vw", }}>
        <Text style={styles.title2}>Mes Créations</Text>
        {isMobile ?
          <ScrollView style={[styles.scrollViewBorder, { height: "55%", overflow: "scroll" }]}>
            <DataList />
          </ScrollView>
          :
          <View style={{ display: "flex", flexWrap: "wrap", height: "35vh" }}>
            <DataList />
          </View>
        }
      </View>
    )
  }

  return (
    <>
      <ProfilName />
      {userData.profilPicture ?
        <ScrollView style={{ height: "55%", overflow: "scroll" }}>
          <UserInformation />
          <CreationInformation />
        </ScrollView>
        :
        <View style={{ display: "flex", flexDirection: isMobile ? "column" : "row", height: "90%", justifyContent: "space-between" }}>
          <UserInformation />
          <CreationInformation />
        </View>
      }
    </>
  );
}