export const checkOnlyNumber = (value) => {
    const reg = new RegExp("^[0-9]*$");
    return reg.test(value)
}
export const getTimeBetween = (condition, value) => {
    const today = new Date().getTime();
    const minute = 60; const heure = 60 * minute; const jour = 24 * heure;
    let timeLaps = (today - value) / 1000; let nbMinutes; let nbHours; let nbDays;
    if (timeLaps < minute) {
        timeLaps = `${Math.floor(timeLaps)} secondes`
    }
    if (timeLaps > minute && timeLaps < heure) {
        nbMinutes = Math.floor(timeLaps / minute);
        timeLaps = `${nbMinutes} minute${nbMinutes > 1 ? "s" : ""}`
    }
    if (timeLaps > heure && timeLaps < jour) {
        nbHours = Math.floor(timeLaps / heure);
        nbMinutes = Math.floor((timeLaps - (nbHours * heure)) / minute)
        condition ? timeLaps = `${nbHours}h ${nbMinutes}m`
        : timeLaps = `${nbHours} heure${nbHours > 1 ? "s" : ""} ${nbMinutes} minute${nbMinutes > 1 ? "s" : ""}`
    }
    if (timeLaps > jour) {
        nbDays = Math.floor(timeLaps / jour);
        nbHours = Math.floor((timeLaps - (nbDays * jour)) / heure);
        nbMinutes = Math.floor((timeLaps - (nbDays * jour) - (nbHours * heure)) / minute);   
        condition ? timeLaps = `${nbDays}j ${nbHours}h ${nbMinutes}m`
        : timeLaps = `${nbDays} jour${nbDays > 1 ? "s" : ""} ${nbHours} heure${nbHours > 1 ? "s" : ""} ${nbMinutes} minute${nbMinutes > 1 ? "s" : ""}`
    }
    return timeLaps
}