// LIBRARY
import { View, Text } from 'react-native'
import React, { useEffect, useState } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage';
// STYLE
// PAGE
import { GetData } from '../../Services/Api/apiCall';
import { useStyle } from '../../Contexts/style.context';
// COMPONENT
// SERVICE
// OTHER

export default function World() {
  const [worldData, setWorldData] = useState()
  const { styles } = useStyle();

  useEffect(() => {
    const fetchData = async () => {
      const dataLoad = await GetData("creation", "world", JSON.parse(await AsyncStorage.getItem("userToken")));
      setWorldData(dataLoad)
    }
    fetchData()
  }, [])
  return (
    <View style={styles.container}>
      <View style={[styles.container, styles.acenter]}>
        <Text style={[styles.title, styles.titleShadow]}> Multivers</Text>
      </View>
    </View>
  )
}