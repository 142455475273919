import { Text, View, TouchableOpacity } from 'react-native';
import { useStyle } from '../../Contexts/style.context';


export const creationSelectBox = (data, selectedData, setSelectedData, key, subKey, setData, showData, setShowData) => {
const { styles } = useStyle();

    const dataValue = data[key];
    return (
        <>
            <TouchableOpacity style={styles.selectboxButton} onPress={() => { showData ? setShowData(undefined) : setShowData(key) }}>
                {selectedData?.[key] ?
                    <>
                        <Text style={styles.text}>{key} :</Text>
                        <Text style={styles.text}>{selectedData[key][subKey]}</Text>
                        <View style={styles.arrow}></View>
                    </>
                    : <>
                        <Text style={styles.text}>{key} :</Text>
                        <View style={styles.arrow}></View>
                    </>
                }
            </TouchableOpacity>
            {showData === key &&
                <View style={styles.unselectBoxButton}>
                    {dataValue.map(value =>
                        <Text key={value[subKey]} style={styles.unselectBoxButtonText} onPress={() => { setData(selectedData, setSelectedData, key, value, subKey); setShowData(undefined) }}>
                            {value[subKey]}
                        </Text>
                    )}
                </View>
            }
        </>
    )
}
export const creationSelectBoxOnSelect = (data, selectedData, setSelectedData, dataKey, key, subKey, setData, showData, setShowData) => {
    const { styles } = useStyle();
    const dataValue = data[dataKey];
    return (
        <>
            <TouchableOpacity style={styles.selectboxButton} onPress={() => { showData ? setShowData(undefined) : setShowData(dataKey) }}>
                {selectedData?.[key] ?
                    <>
                        <Text style={styles.text}>{key} :</Text>
                        <Text style={styles.text}>{selectedData[key][subKey]}</Text>
                        <View style={styles.arrow}></View>
                    </>
                    : <>
                        <Text style={styles.text}>{key} :</Text>
                        <View style={styles.arrow}></View>
                    </>
                }
            </TouchableOpacity>
            {showData === dataKey &&
                <View style={styles.unselectBoxButton}>
                    {dataValue.map(value => <Text key={value[subKey]} style={styles.unselectBoxButtonText}
                        onPress={() => { setData(selectedData, setSelectedData, key, value); setShowData(undefined) }}>{value[subKey]}</Text>)}
                </View>
            }
        </>
    )
}


export const creationSelectBoxMultiple = (data, selectedData, setSelectedData, key, subKey, setData, showData, setShowData) => {
    const { styles } = useStyle();
    const dataValue = data[key];

    return (
        <>
            <TouchableOpacity style={styles.selectboxButton} onPress={() => { showData ? setShowData(undefined) : setShowData(key) }}>
                <Text style={styles.text}>{key === "Item" ? "Inventaire" : key} :</Text>
                <View style={styles["arrow"]}></View>
            </TouchableOpacity>
            {showData === key &&
                <View style={styles.unselectBoxButton}>
                    {dataValue.map(value => {
                        return (
                            <TouchableOpacity style={styles.unselectBoxTextButton} key={value[subKey]} onPress={() => { setData(selectedData, setSelectedData, key, value, subKey); setShowData(undefined) }}>
                                <Text style={styles.unselectBoxButtonText}>{key === "Arkane" ? `- ${value[subKey]} ` : `- ${value[subKey]}`}</Text>
                            </TouchableOpacity>
                        )
                    })}
                </View>
            }
            {selectedData?.[key] &&
                selectedData[key].map((el, index) => {
                    return (
                        <Text key={`${el[subKey], index}`} style={styles.text}>
                            {`${el[subKey]}`}
                        </Text>
                    )
                }
                )}
        </>
    )
}