// LIBRARY
import { useEffect, useState } from 'react';
import { Text, View, ScrollView, TouchableOpacity, Image } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
// STYLE
// PAGE
// COMPONENT
// SERVICE
// OTHER
import { useAuth } from '../../Contexts/auth.context';
import CharacterDetail from './characterDetail';
import { useStyle } from '../../Contexts/style.context';


export default function Character({ navigation }) {
    const { userData } = useAuth();
    const { styles } = useStyle();
    const characterData = userData.Personnage;
    const [token, setToken] = useState()
    const [selectedCharacter, setSelectedCharacter] = useState()
    const [selectedInformation, setSelectedInformation] = useState("description")

    useEffect(() => {
        const getToken = async () => { setToken(JSON.parse(await AsyncStorage.getItem("userToken"))) }
        getToken()
    }, [])

    const setCharacterDivision = (value) => setSelectedInformation(value)

    return (
        <View style={styles.container}>
            {!selectedCharacter &&
                <>
                    <View style={[styles.container, styles.acenter]}>
                        <Text style={[styles.title, styles.titleShadow]}> Mes Personnages </Text>
                    </View>
                    <View style={styles.subContainer}>
                        {characterData.map(c =>
                            <TouchableOpacity key={`${c._id}`} onPress={() => setSelectedCharacter(c)}>
                                <Text style={styles.text}>- {c.Nom}</Text>
                            </TouchableOpacity>
                        )}

                    </View>
                </>
            }
            {selectedCharacter &&
                <>
                    {selectedInformation === "description" &&
                        <View style={styles.characterDivisionContainer}>
                            <TouchableOpacity
                                style={styles.characterDivisionButton} onPress={() => setCharacterDivision("description")}>
                                <Image style={styles.characterDivisionIcon} source={require("../../assets/images/icon-parchemin.png")} />
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={styles.characterDivisionButtonDisable} onPress={() => setCharacterDivision("fight")}>
                                <Image style={styles.characterDivisionIcon} source={require("../../assets/images/battle-icon.jpeg")} />
                            </TouchableOpacity>
                        </View>}
                    {selectedInformation === "fight" &&
                        <View style={styles.characterDivisionContainer}>
                            <TouchableOpacity
                                style={styles.characterDivisionButtonDisable} onPress={() => setCharacterDivision("description")}>
                                <Image style={styles.characterDivisionIcon} source={require("../../assets/images/icon-parchemin.png")} />
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={styles.characterDivisionButton} onPress={() => setCharacterDivision("fight")}>
                                <Image style={styles.characterDivisionIcon} source={require("../../assets/images/battle-icon.jpeg")} />
                            </TouchableOpacity>
                        </View>}
                    <CharacterDetail data={selectedCharacter} option={selectedInformation} />
                </>
            }
        </View>
    )
}
