import React , { createContext } from "react";
import { StyleSheet } from 'react-native';
import useDimensions from "../Services/Hook/useDimensions";

const StyleContext = createContext({});

export const StyleProvider = props => {
    const {width,height} = useDimensions()
    const isMobile = width < 768;
    const mainTitleSize = width * (isMobile ? 0.2 : 0.11);
    const titleSize = width * (isMobile ? 0.1 : 0.05) ;
    const title2Size = width * (isMobile ? 0.07 : 0.03) ;
    const textSize = width * (isMobile ? 0.06 : 0.02) ;
    const text2Size = width * (isMobile ? 0.08 : 0.03) ;
    const arrowSize = width * (isMobile ? 0.007 : 0.007) ;
    const popUpHeight = height * (isMobile ? 0.25 : 0.4) ;
    const popUpWidth = width * (isMobile ? 0.8 : 0.4) ;
    const popUpButtonHeight = height * (isMobile ? 0.06 : 0.06) ;
    const popUpButtonWidth = width * (isMobile ? 0.08 : 0.08) ;
    const popUpButtonBottomPosition = -(height*0.03-1);

    const styles = StyleSheet.create({

        // CONTAINER
        mainContainer: {
            alignItems: 'center',
            justifyContent: "center",
            position: "relative",
        },
        rowContainer: {
            display: 'flex',
            flexDirection: "row",
            marginVertical: 5
        },
        container: {
            display: 'flex',
            marginVertical: 5,
            paddingHorizontal: 15,
        },
        menuButtonContainer:{
            position: "relative",
            display: "flex",
            alignItems: "center",
            borderColor: "rgba(0,0,0,0.7)",
            borderTopWidth: 0.5,
            borderRightWidth: 0.5,
            borderLeftWidth: 2,
            borderBottomWidth: 2,
            borderRadius: 75,
            
        },
        acenter: {alignItems: "center"},
        jcenter: {justifyContent: "center"},
    
        // SCROLLVIEW
        scrollViewBorder : {
            borderBottomColor: "rgba(0,0,0,0.5)",
            borderBottomWidth: 3,
            borderTopColor: "rgba(0,0,0,0.5)",
            borderTopWidth: 3,
            paddingHorizontal: 15,
            marginHorizontal: 15,
        },
        //TITLE
        mainTitle: {
            fontSize: mainTitleSize,
            fontFamily: 'imf',
            fontWeight: !isMobile ? "bold" : "normal",
            textShadowRadius: 9,
            textShadowColor: isMobile ? "crimson" : 'red',
            textShadowOffset: { width: isMobile ? 1: 2, height: isMobile ? -1: -2 },
        },
        title: {
            fontSize: titleSize,
            fontFamily: 'cookie',
            fontWeight: !isMobile ? "bold" : "normal",
            marginVertical: 5,
        },
        titleShadow: {
            textShadowRadius: 8,
            textShadowColor: 'red',
            textShadowOffset: { width: 2, height: -1 },
            
        },
        title2: {
            fontSize: title2Size,
            fontFamily: 'cookie',
            fontWeight: !isMobile ? "bold" : "normal",
            textDecorationLine: "underline",
            paddingVertical: 20,
            marginLeft: 20
        },
        
        // TEXT
        disable: {
            opacity: 0.5,
        },
        text: {
            fontSize: textSize,
            fontFamily: "cookie",
            paddingHorizontal: 10,
        },
        text2: {
            fontSize: text2Size,
            fontFamily: "cookie",
            paddingHorizontal: 10,
        },
        textBold: {
            textShadowRadius: 1,
            textShadowColor: 'black',
            textShadowOffset: { width: 1, height: -1 },  
        },
        
    
        // BUTTON
        button: {
            textAlign: 'center',
            // backgroundColor: 'black',
            borderRadius: 10,
            borderColor: 'rgba(0,0,0,0.2)',
            // borderWidth: 1,
            marginVertical: 10,
            paddingVertical: 5,
            // maxWidth: '60%',
            textShadowRadius: 1,
            textShadowColor: 'red',
            textShadowOffset: { width: 2, height: -2 },
        },
        // INPUT
        
    
        // Icon
        characterDivisionIcon: {
            height: 25,
            width: 25,
        },
        // ARROW
        arrow: {
            marginLeft: 5,
            width: 0,
            height: 0,
            marginBottom: -3,
            borderTopColor: "black",
            borderLeftColor: "transparent",
            borderRightColor: "transparent",
            borderLeftWidth: arrowSize,
            borderRightWidth: arrowSize,
            borderTopWidth: arrowSize
        },
        
    
        // POP-UP
        popUp: {
            position: "relative",
            height: popUpHeight,
            width: popUpWidth,
        },
        popUpButton: {
            position: "absolute",
            bottom: popUpButtonBottomPosition,
            height: popUpButtonHeight,
            width: popUpButtonWidth,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderWidth: 1,
            borderRadius: 10,
            borderColor: "black",
            backgroundColor: "silver",
        },
        popUpButtonValidate: {
            backgroundColor: "lightgreen"
        },
    
    });

    const styleContextValue = {styles, isMobile};
    return <StyleContext.Provider value={styleContextValue} {...props} />
};
export const useStyle = () => React.useContext(StyleContext)