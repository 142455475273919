import { useEffect, useState } from 'react';
import { StatusBar } from 'react-native';
import { loadAsync } from 'expo-font';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { ContextProvider } from './Contexts/context';
import Tablet from './Pages/save';
import Index from './Pages';



export default function App() {
  const [fontLoad, setFontLoad] = useState(false);

  useEffect(() => {

    const fontLoading = async () => {
      await loadAsync({
        meaculpa: require('./assets/fonts/MeaCulpa-Regular.ttf'),
        BlackOps: require('./assets/fonts/BlackOpsOne-Regular.ttf'),
        cinzel: require('./assets/fonts/Cinzel.ttf'),
        dancingScript: require('./assets/fonts/DancingScript.ttf'),
        ephesis: require('./assets/fonts/Ephesis-Regular.ttf'),
        imf: require('./assets/fonts/IMF.ttf'),
        orbitron: require('./assets/fonts/Orbitron.ttf'),
        sancreek: require('./assets/fonts/Sancreek.ttf'),
        stickNoBILLS: require('./assets/fonts/StickNoBills.ttf'),
        cookie: require('./assets/fonts/Cookie.ttf'),
      }).then(res => setFontLoad(true))
        .catch(err => {
          setFontLoad(true);
        })
    }
    fontLoading()
  }, [])

  if (!fontLoad) {
    return null
  }

  return (
    <SafeAreaProvider>
      <ContextProvider>
        <StatusBar hidden={false} backgroundColor="#c4b493"/>
        <Index />
        {/* <Tablet/> */}
      </ContextProvider>
    </SafeAreaProvider>
  );
}
