import { StyleSheet, ImageBackground, View } from 'react-native'
import React from 'react'
import background from '../../assets/images/background.jpg';

const Background = ({ children }) => {
    return (
        <View style={styles.container}>
            <ImageBackground source={background} style={[styles.background]}>
                {children}
            </ImageBackground>
        </View>
    )
}

export default Background;
const styles = StyleSheet.create({
    container : {
        flex: 1
    },
    background: {
        position: "relative", flex: 2, height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center",
    }
});