// LIBRARY
import { useEffect, useState } from 'react';
import { Text, View, ScrollView, TextInput, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
// STYLE
// PAGE
// COMPONENT
import popUp from '../../Components/Pop-up/popUp';
import { creationSelectBox, creationSelectBoxOnSelect } from '../../Components/Box/selectBox';
// SERVICE
import { addDataToObject } from '../../Services/TransformationData/transform';
import { GetData, PostData } from '../../Services/Api/apiCall';
// OTHER
import { useAuth } from '../../Contexts/auth.context';
import { checkOnlyNumber } from '../../Services/Verification/verification';
import { useStyle } from '../../Contexts/style.context';


export default function Creation({ name }) {
    const { userData, setUserData } = useAuth();
    const { isMobile, styles } = useStyle();
    const worldData = userData.Monde[0];
    const [token, setToken] = useState()
    const [step, setStep] = useState(1);
    const [showData, setShowData] = useState()
    const [showResponse, setShowResponse] = useState(false)
    const [dataResponse, setDataResponse] = useState()
    const [creationData, setCreationData] = useState()
    const keyName = {
        "ArkAttribut": { name: "d'Ark attribut", key: "arkAttribute" },
        "ArkCatégorie": { name: "d'Ark catégorie", key: "arkCategorie" },
        "Arkane": { name: "d'Arkane", key: "arkane" },
        "Arkence": { name: "d'Arkence", key: "arkence" },
        "Catégorie-item": { name: "Catégorie d'item", key: "itemCategory" },
        "Classe": { name: "de Classe", key: "class" },
        "Don": { name: "de Don", key: "gift" },
        "Effet": { name: "d'Effet", key: "effect" },
        "ArkType": { name: "d'Ark types", key: "arkType" },
        "Dé": { name: "de dé", key: "dice" },
        "Espèce": { name: "d'Espèce", key: "specie" },
        "Item": { name: "d'Item", key: "item" },
        "Malédiction": { name: "de Malédiction", key: "curse" },
        "Race": { name: "de Race", key: "race" },
        "Rang": { name: "de Rang", key: "rank" },
        "Sous-catégorie-item": { name: "Sous-catégorie d'item", key: "itemSubcategory" },
        "Titre": { name: "de Titre", key: "title" },
    }
    const body = {
        creation: creationData,
        Créateur: { id: userData._id, key: `${name}` },
        Monde: { id: worldData._id, key: `${name}` }
    }

    useEffect(() => {
        setCreationData({
            ...creationData,
            Coût: creationData?.Effet.Coût,
            Point: creationData?.Effet.Point
        })
    }, [creationData?.Effet])

    useEffect(() => {
        (creationData !== undefined && creationData.Dé !== undefined && creationData.Type !== undefined) &&
            setCreationData({
                ...creationData,
                Coût: creationData?.Type?.Multiplicateur * creationData?.Dé?.Valeur,
                Point: creationData?.Type?.Point
            })
    }, [creationData?.Type, creationData?.Dé])

    useEffect(() => {
        const getToken = async () => { setToken(JSON.parse(await AsyncStorage.getItem("userToken"))) };
        getToken();
        const getUserData = async () => {
            const user = await GetData("users", userData._id, JSON.parse(await AsyncStorage.getItem("userToken")))
            setUserData(user)
        };
        // getUserData();
        setStep(1);
        setCreationData(undefined);
    }, [name])

    const inputTextValue = (value) => creationData?.[value]
    const nextStep = () => { setStep(step + 1) };
    const previousStep = () => { setStep(step - 1) };


    const recapInformation = () => {

        return (
            <View style={styles.sectionContainer}>
                <View style={styles.subContainer}>
                    <Text style={styles.sectiontTitle}>Récapitulatif</Text>
                    <Text style={styles.text}>Nom : {creationData?.Nom}</Text>
                    <Text style={styles.text}>Description : {creationData?.Description}</Text>
                    {creationData?.Rang && <Text style={styles.text}>Rang : {creationData?.Rang.Nom}</Text>}
                    {creationData?.Type && <Text style={styles.text}>Type : {creationData?.Type?.Nom}</Text>}
                    {creationData?.Effet && <Text style={styles.text}>Effet : {creationData?.Effet?.Nom}</Text>}
                    {creationData?.Dé && <Text style={styles.text}>Dé : {creationData?.Dé?.Valeur}</Text>}
                    {creationData?.Coût && <Text style={styles.text}>Coût : {creationData?.Coût} Ark</Text>}
                    {creationData?.Durée && <Text style={styles.text}>Durée : {creationData?.Durée} tour(s)</Text>}
                    {creationData?.Point && <Text style={styles.text}>Point : {creationData?.Point}</Text>}
                    {creationData?.Multiplicateur && <Text style={styles.text}>Multiplicateur : {creationData?.Multiplicateur}</Text>}
                    {creationData?.Catégorie && <Text style={styles.text}>Catégorie : {creationData?.Catégorie.Nom}</Text>}
                    {creationData?.["Sous-catégorie"] && <Text style={styles.text}>Sous-catégorie : {creationData?.["Sous-catégorie"].Nom}</Text>}
                </View>
            </View>
        )
    }
    return (
        <View style={styles.container}>
            <View style={[styles.container, styles.acenter]}>
                <Text style={[styles.title, styles.titleShadow]}> Création {keyName?.[name]?.name} </Text>
            </View>
            <ScrollView style={[styles.scrollView, { height: isMobile ? "45%" : "75vh" }]}>
                {step === 1 &&
                    <>
                        <View style={styles.subContainer}>
                            <View style={styles.sectionInput}>
                                <Text style={styles.text}>Nom : </Text>
                                <TextInput style={styles.text} placeholder={"Nom..."} placeholderTextColor={"white"} defaultValue={inputTextValue("Nom")} onChangeText={text => addDataToObject(creationData, setCreationData, "Nom", text)} />
                            </View>
                            <View style={styles.sectionInput}>
                                <Text style={styles.text}>Description : </Text>
                                <TextInput style={styles.text} placeholder={"Description..."} placeholderTextColor={"white"} defaultValue={inputTextValue("Description ")} onChangeText={text => addDataToObject(creationData, setCreationData, "Description", text)} />
                            </View>
                            {name === "ArkType" &&
                                <>
                                    <View style={styles.sectionInput}>
                                        <Text style={styles.text}>Point :</Text>
                                        <TextInput
                                            style={styles.text}
                                            placeholder={"Point..."}
                                            placeholderTextColor={"white"}
                                            defaultValue={inputTextValue("Point")}
                                            keyboardType="number-pad"
                                            onChangeText={text => {
                                                if (checkOnlyNumber(text) === true) addDataToObject(creationData, setCreationData, "Point", text)
                                            }}
                                        />
                                    </View>
                                    <View style={styles.sectionInput}>
                                        <Text style={styles.text}>Multiplicateur :</Text>
                                        <TextInput
                                            style={styles.text}
                                            placeholder={"Multiplicateur..."}
                                            placeholderTextColor={"white"}
                                            keyboardType="number-pad"
                                            defaultValue={inputTextValue("Multiplicateur")}
                                            onChangeText={text => {
                                                if (checkOnlyNumber(text) === true) addDataToObject(creationData, setCreationData, "Multiplicateur", text)
                                            }}
                                        />
                                    </View>
                                </>
                            }
                            {(name === "Don" || name === "Malédiction" || name === "Catégorie-item" || name === "Sous-catégorie-item" || name === "Titre") &&
                                creationSelectBox(worldData, creationData, setCreationData, "Rang", "Nom", addDataToObject, showData, setShowData)
                            }
                            {(name === "Effet" || name === "Arkane") &&
                                creationSelectBoxOnSelect(worldData, creationData, setCreationData, "ArkType", "Type", "Nom", addDataToObject, showData, setShowData)
                            }
                            {(name === "Arkane" && creationData?.Type?.Nom === "Altération de statut") &&
                                creationSelectBox(worldData, creationData, setCreationData, "Effet", "Nom", addDataToObject, showData, setShowData)
                            }
                            {(name === "Arkane" && creationData?.Type?.Nom !== "Altération de statut") &&
                                creationSelectBox(worldData, creationData, setCreationData, "Dé", "Valeur", addDataToObject, showData, setShowData)
                            }
                            {name === "Effet" &&
                                creationData?.Type?.Nom === "Altération de statut" &&
                                <View style={styles.sectionInput}>
                                    <Text style={styles.text}>Durée : </Text>
                                    <TextInput style={styles.text} placeholder={"Durée de Effet"} placeholderTextColor={"white"} defaultValue={inputTextValue("Durée")} keyboardType="number-pad"
                                        onChangeText={text => {
                                            if (checkOnlyNumber(text) === true) addDataToObject(creationData, setCreationData, "Durée", text)
                                        }} />
                                </View>
                            }
                            {name === "Espèce" &&
                                creationSelectBox(worldData, creationData, setCreationData, "Race", "Nom", addDataToObject, showData, setShowData)
                            }
                            {name === "Item" &&
                                <>
                                    {creationSelectBoxOnSelect(worldData, creationData, setCreationData, "Catégorie-item", "Catégorie", "Nom", addDataToObject, showData, setShowData)}
                                    {creationData?.Catégorie && creationSelectBoxOnSelect(creationData.Catégorie, creationData, setCreationData, "Sous-catégorie", "Sous-catégorie", "Nom", addDataToObject, showData, setShowData)}
                                </>
                            }
                            {name === "Sous-catégorie-item" &&
                                creationSelectBox(worldData, creationData, setCreationData, "Catégorie-item", "Nom", addDataToObject, showData, setShowData)
                            }
                        </View>
                        <View style={styles.buttonSectionSimple}>
                            <TouchableOpacity style={styles.buttonNext} onPress={nextStep}>
                                <Text style={styles.text}>Suivant</Text>
                            </TouchableOpacity>
                        </View>
                    </>

                }
                {step === 2 &&
                    <>
                        {recapInformation()}
                        <View style={styles.buttonSection}>
                            <TouchableOpacity style={styles.buttonPrevious} onPress={previousStep}>
                                <Text style={styles.text}>Précedent</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.buttonSend} onPress={async () => {
                                setShowResponse(true);
                                setDataResponse(await PostData("creation", keyName?.[name].key, token, body));
                            }}>
                                <Text style={styles.text2}>Créer</Text>
                            </TouchableOpacity>
                        </View>
                    </>
                }
            </ScrollView >
            {(showResponse && dataResponse) && popUp(`${dataResponse.Nom} a été créer !`, setShowResponse)}
        </View >
    )
}