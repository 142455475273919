// LIBRARY
import { ImageBackground, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'
import { useStyle } from '../../Contexts/style.context';
// STYLE
// PAGE
// COMPONENT
// SERVICE
// OTHER

export default function Register({ navigation, setAuthPage }) {
    const { styles } = useStyle()
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("")
    const { handleSubmit } = useForm();

    

    const getCurrentDate = () => {
        const date = new Date().getDate();
        const month = new Date().getMonth() + 1;
        const year = new Date().getFullYear();

        return date + '/' + (month < 10 ? `0${month}` : month) + '/' + year;//format: dd-mm-yyyy;
    }

    const registration = async (value) => {
        const res = await fetch("http://arkhanya-server.herokuapp.com/register", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(value)
        });
        const resJson = await res.json();
        return resJson;
    }

    const submitClicked = async () => {
        const verification = await registration({ username: username, password: password, dateOfCreation: getCurrentDate() })
        if (verification.message !== "valid register") alert(verification.message)
        if (verification.message === "valid register") {
            setAuthPage('Signin')
        }
    }

    return (
                <View style={[styles.container, styles.acenter]} onSubmit={handleSubmit(submitClicked)}>
                    <TouchableOpacity style={[styles.rowContainer, styles.jcenter]}>
                        <Text style={[styles.title2, styles.disable]} onPress={() => setAuthPage('Signin')} >S'identifier</Text>
                        <Text style={[styles.text2, styles.textBold,{paddingVertical: 20}]} > / </Text>
                        <Text style={[styles.title2, styles.textBold]}>S'enregister</Text>
                    </TouchableOpacity>
                    <View style={styles.rowContainer}>
                        <Text style={styles.text}>Nom d'utilisateur : </Text>
                        <TextInput
                            style={styles.text}
                            defaultValue="_ _ _ _ _ _ _ _ _ _"
                            onChangeText={username => setUsername(username)}
                        />
                    </View>
                    <View style={styles.rowContainer}>
                        <Text style={styles.text}>Mot de passe : </Text>
                        <TextInput
                            style={styles.text}
                            secureTextEntry={true}
                            defaultValue=""
                            placeholder="_ _ _ _ _ _ _ _ _ _"
                            onChangeText={password => setPassword(password)}
                        />
                    </View>
                    <View style={[styles.rowContainer, styles.acenter, styles.jcenter]}>
                        <Text style={[styles.text2, styles.titleShadow]}>{">>>"}</Text>
                        <TouchableOpacity style={styles.button} onPress={submitClicked}>
                            <Text style={[styles.text2, styles.textBold]}>Rejoindre Arkhanya!</Text>
                        </TouchableOpacity>
                        <Text style={[styles.text2, styles.titleShadow]}>{"<<<"}</Text>
                    </View>
                </View>
    )
}