import React, { createContext, useEffect , useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = props => {
    const [ isLogged, setIsLogged ] = useState(false);
    const [ userData, setUserData ] = useState();
    const [ userToken, setUserToken ] = useState(false);

    const setAuth = () => setIsLogged(!isLogged);

    const authContextValue = { isLogged, setAuth, userData, setUserData, userToken, setUserToken }

    return <AuthContext.Provider value={authContextValue} {...props} />
};
export const useAuth = () => React.useContext(AuthContext);