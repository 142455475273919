import { View, Text, TouchableOpacity } from 'react-native'
import React, { useState } from 'react'
import { useStyle } from '../../Contexts/style.context';
import Parchment from '../parchment/parchment';

export default function PopUp({ value, setDisplay }) {
  const { styles } = useStyle();
  const [validation, setValidation] = useState();

  const handleSubmit = () => {
    setValidation(true)
    setTimeout(() => setDisplay(false), 2000);
  };

  return (
    <View style={styles.popUp}>
      <Parchment height={90} width={100}>
        <View style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
          <Text style={styles.text}>{value}</Text>
          {setDisplay !== null &&
            <TouchableOpacity style={[styles.popUpButton, validation && styles.popUpButtonValidate]} onPress={() => handleSubmit()}>
              <Text style={styles.text}>Valider</Text>
            </TouchableOpacity>
          }
        </View>
      </Parchment>
    </View>
  )
}