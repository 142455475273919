export const Login = async (route, value) => {

  const res = await fetch(`https://arkhanya-server.herokuapp.com/${route}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Content-Length": "<calculated when request is sent>"
    },
    body: JSON.stringify(value)
  });
  const resJson = res.json()
  return resJson
}

export const UpdateLastConnexion = async (route, value, token) => {

  const getCurrentDate = () => {
    const date = new Date().getDate();
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();

    return (date < 10 ? `0${date}` : date) + '/' + (month < 10 ? `0${month}` : month) + '/' + year;//format: dd-mm-yyyy;
  }

  const updateConnexion = await fetch(`https://arkhanya-server.herokuapp.com/${route}/${value}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Content-Length": "<calculated when request is sent>",
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      Connexion: {
        date: getCurrentDate(),
        time: new Date().getTime()
      }
    })
  });
  const jsonUpdateConnexion = updateConnexion.json()
  return jsonUpdateConnexion
}

export const GetData = async (route, page, token) => {

  try {
    // const res = await fetch(`http://localhost:8000/${route}/${page}`, {
    const res = await fetch(`https://arkhanya-server.herokuapp.com/${route}/${page}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`
      },
    });
    const resJson = await res.json();
    return resJson

  } catch {
    return console.log("error")
  }
}

export const PostData = async (route, page, token, body) => {
  // const res = await fetch(`http://localhost:8000/${route}/${page}`, {
  const res = await fetch(`https://arkhanya-server.herokuapp.com/${route}/${page}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Content-Length": "<calculated when request is sent>",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(body)
  });
  const resJson = await res.json();
  return resJson
}

export const PutData = async (route, page, token, body) => {
  const res = await fetch(`https://arkhanya-server.herokuapp.com/${route}/${page}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Content-Length": "<calculated when request is sent>",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(body)
  });
  const resJson = await res.json();
  return resJson
}


export const PatchData = async (route, page, token, body) => {
  const res = await fetch(`https://arkhanya-server.herokuapp.com/${route}/${page}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Content-Length": "<calculated when request is sent>",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(body)
  });
  const resJson = await res.json();
  return resJson
}


export const DeleteData = async (route, page, token, body) => {
  const res = await fetch(`https://arkhanya-server.herokuapp.com/${route}/${page}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Content-Length": "<calculated when request is sent>",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(body)
  });
  const resJson = await res.json();
  return resJson
}