import React, { useState, useEffect } from 'react';
import { StyleSheet, Dimensions } from 'react-native';
const { width, height } = Dimensions.get("window");



export const styles = StyleSheet.create({

    // CONTAINER
    mainContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: "center",
        position: "relative",
    },

    // SCROLLVIEW

    //TITLE
    mainTitle: {
        fontFamily: 'imf',
        fontWeight: "bold",
        textShadowRadius: 9,
        textShadowColor: 'red',
        textShadowOffset: { width: 2, height: -2 },
    },
    
    // TEXT
    text: {
        fontFamily: "cookie",
    },
    scrollView : {
        height: "80%",
        borderBottomColor: "rgba(0,0,0,0.5)",
        borderBottomWidth: 3,
        borderTopColor: "rgba(0,0,0,0.5)",
        borderTopWidth: 3,
        margin: 15,
        overflow: "scroll"
    },

    // BUTTON

    // INPUT

    // Icon
    characterDivisionIcon: {
        height: 25,
        width: 25,
    },
    // ARROW

    // POP-UP

});