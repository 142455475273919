// LIBRARY
import { useEffect, useState, useRef } from 'react';
import { Text, View, Animated } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
// STYLE
import { useStyle } from '../Contexts/style.context';
// PAGE
import Register from './Auth/register';
import Signin from './Auth/signin';
// COMPONENT
import Background from '../Components/background/background';
// SERVICE
// OTHER
import { useAuth } from '../Contexts/auth.context';
import Navigation from '../Components/Navigation/navigation';


export default function Index() {
  const { isLogged, setUserData } = useAuth()
  const { styles, isMobile } = useStyle();
  const [authPage, setAuthPage] = useState("Signin");
  const slideUp = useRef(new Animated.Value(!isMobile ? (250) : (400))).current;
  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    setTimeout(() => SlideUp(), 2000);
    setTimeout(() => fadeIn(), 3000);
    const getData = async () => { setUserData(JSON.parse(await AsyncStorage.getItem("userData"))) };
    getData()
  }, [])
  const titleHeight = !isMobile ? (50) : (250)

  const SlideUp = () => {
    Animated.timing(slideUp, {
      toValue: titleHeight,
      duration: 1000,
      useNativeDriver: true
    }).start();
  };
  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true
    }).start();
  };

  const HomepageRender = () => {
    return (
      <View style={styles.mainContainer}>
        <Animated.View style={{ display: "flex", alignItems: "center", transform: [{ translateY: slideUp }] }}>
          {isMobile ?
            <>
              <Text style={styles.mainTitle}>World</Text>
              <Text style={styles.mainTitle}>of</Text>
              <Text style={styles.mainTitle}>Arkhanya</Text>
            </>
              : <Text style={styles.mainTitle}>World of Arkhanya</Text>
          }
        </Animated.View>
        <Animated.View style={{ opacity: fadeAnim, width: "100%", height: isMobile ? 800 : 450, justifyContent: "center", alignItems: "center" }} >
          {authPage === "Signin" && <Signin setAuthPage={setAuthPage} />}
          {authPage === "Register" && <Register setAuthPage={setAuthPage} />}
        </Animated.View>
      </View>
    )
  }

  return (
    <Background>
      {isLogged ? <Navigation /> : <HomepageRender />}
    </Background>
  );
}