//import liraries
import React, { useState } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import { useAuth } from '../../Contexts/auth.context';
import { usePage } from '../../Contexts/page.context';
import { useStyle } from '../../Contexts/style.context';
import { transformObjectToData } from '../../Services/TransformationData/transform';

// create a component
export const NavigationPage = () => {
    const { isMobile, styles } = useStyle();
    const { userData, setAuth } = useAuth();
    const { lastPage, page, submitPage, pageOption, submitPageOption } = usePage();
    const [currentPage, setCurrentPage] = useState(undefined)

    const NavigationList = () => {
        const list = ["Profil", "Monde", "Création", "Personnage", "Bibliothèque"];
        const dataArr = transformObjectToData(userData);
        dataArr.sort((a, b) => a.key > b.key ? 1 : -1);
        const handleClick = (value) => {
            value === "Création" ?
                setCurrentPage(currentPage ? undefined : value)
                : submitPage(value)
        }
        return (
            <View>
                {list.map(e =>
                    <>
                        <TouchableOpacity key={`navigationlist-${e}`} style={{ margin: 10 }} onPress={() => handleClick(e)}>
                            <Text style={styles.text}>{`-> ${e}`}</Text>
                        </TouchableOpacity>
                        {(currentPage === "Création" && e === "Création") &&
                            <ScrollView style={[styles.scrollViewBorder, { height: isMobile ? "45%" : "35vh" }]} showsVerticalScrollIndicator={true} indicatorStyle="default">
                                {dataArr.map(item => {
                                    if (item.key !== "_id" && item.key !== "__v" && item.key !== "username" && item.key !== "Création" && item.key !== "Monde" && item.key !== "Stats" && item.key !== "Sous-stats") {
                                        return (
                                            <TouchableOpacity key={`profil-${item.key}`} onPress={() => submitPageOption(item.key)}>
                                                <Text style={styles.text}>{item.key.split('-item').join(" d'item")}</Text>
                                            </TouchableOpacity>
                                        )
                                    }
                                })}
                            </ScrollView>
                        }
                    </>
                )}
            </View>
        )
    }
    return (
        <>
            <View style={[styles.container, styles.acenter]}>
                <Text style={[styles.title, styles.titleShadow]}>Menu</Text>
            </View>
            <View style={{ display: "flex", justifyContent: "space-between", height: "80%" }}>
                <NavigationList />
                <TouchableOpacity onPress={() => setAuth()}>
                    <Text style={styles.text}>{`-> Sortir d'Arkhanya`}</Text>
                </TouchableOpacity>
            </View>
        </>
    );
};
