import { View, Text, TouchableOpacity, Image } from 'react-native'
import React from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import { useStyle } from '../../Contexts/style.context';
import { usePage } from '../../Contexts/page.context';
import calc from '../../assets/images/calc-parchment.png';

const MenuButton = () => {
  const { isMobile, styles } = useStyle();
  const { page, submitPage } = usePage();
  const backgroundButton = ["#6c3f02", "#9f6913", "#fbcd76", "#9f6913", "#6c3f02"];
  const list = ["Monde", "Création", "Personnage", "Bibliothèque", "Profil"];
  const calcStyle = { position: "absolute", height: 34, width: "98%", opacity: 0.9, borderRadius: 12, left: 0 }
  const textStyle = [
    styles.text, 
    { paddingVertical: 4, paddingHorizontal: 8, borderRadius: 75, marginRight: 2 }, 
    isMobile && {fontSize: 15}
  ]
  return (
    list.map(e => page !== e &&
      <LinearGradient colors={backgroundButton} style={{ borderRadius: 75 }} key={e}>
        <TouchableOpacity onPress={() => submitPage(e)} style={styles.menuButtonContainer} >
          <Image source={calc} style={[calcStyle,{top: 0.1}]} />
          <Image source={calc} style={[calcStyle,{bottom: 0.1, transform: [{ rotate: '180deg' }]}]} />
          <Text style={textStyle}>{e}</Text>
        </TouchableOpacity>
      </LinearGradient>
    )
  )
}

export default MenuButton