import { View, Image } from 'react-native';
import React, { useEffect } from 'react';
import Animated, { useSharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated';
import { LinearGradient } from 'expo-linear-gradient';
import parchment from '../../assets/images/parchment.jpg';
import calc from '../../assets/images/calc-parchment.png';
import { usePage } from '../../Contexts/page.context';
import { useStyle } from '../../Contexts/style.context';
import MenuButton from '../Button/menuButton';


const Parchment = ({ children, height, width }) => {
    const { isMobile } = useStyle();
    const { page, showParchment } = usePage();

    useEffect(() => {
        showParchment ?
            (animationHeight.value = { height: height }, setTimeout(() => animationOpacity.value = { opacity: 1 }, 400))
            : (animationHeight.value = { height: 0 }, animationOpacity.value = { opacity: 0 });
    }, [showParchment])

    const animationHeight = useSharedValue({ height: height });
    const animationOpacity = useSharedValue({ opacity: 1 });
    const animationHeightStyle = useAnimatedStyle(() => {
        return {
            height: withTiming(`${animationHeight.value.height}%`, { duration: 500 })
        }
    })
    const animationOpacityStyle = useAnimatedStyle(() => {
        return {
            opacity: withTiming(animationOpacity.value.opacity, { duration: 500 })
        }
    })

    const backgroundBorder = ["#6c3f02", "#a35f04", "#d3913a", "#a35f04", "#6c3f02"];
    // Style
    const container = {
        position: "relative", height: "94%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center"
    }
    // const parchmentSize = { height: displayParchment, width: `${width}%` };
    const parchmentSize = { height: `100%`, width: isMobile ? "95%" : "97%", zIndex: 0 };
    const parchementContent = { position: "absolute", height: `${height}%`, width: parchmentSize.width }
    const borderParchment = { position: "relative", height: isMobile ? 15 : 15, width: `${width + (isMobile ? 10 : 10)}%`, zIndex: 10, borderRadius: 10 };
    const borderShadow = {
        borderWidth: 0, backgroundColor: "transparent", borderColor: "rgba(0,0,0,0.9)", shadowColor: '#000', shadowOpacity: 0.5, shadowRadius: 5, shadowOffset: { width: 0, height: 8 }
    }
    const borderEnd = { position: "absolute", top: "-50%", height: "200%", width: isMobile ? 8 : 10, borderRadius: 5 };
    const borderLeft = { left: isMobile ? 5 : 12 };
    const borderRight = { right: isMobile ? 5 : 12 };
    const calcParchement = { position: "absolute", height: "40%", width: "100%", opacity: 0.35 };
    const calcDown = { bottom: "0%", transform: [{ rotate: '180deg' }] };
    const calcUp = { top: "0%" };


    // Container
   

    const Container = children => <View style={container} {...children} />
    const Border = ({ children, menu }) => {
        return (
            <LinearGradient colors={backgroundBorder} style={[borderParchment, borderShadow]} {...children}>
                <LinearGradient colors={backgroundBorder} style={[borderEnd, borderLeft, borderShadow]} />
                <LinearGradient colors={backgroundBorder} style={[borderEnd, borderRight, borderShadow]} />
                {(page && menu) &&
                    <Animated.View style={[{ position: "absolute", zIndex: 15, bottom: -20, right: 15, display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", width: "92%", top: -20 },]}>
                        <MenuButton />
                    </Animated.View>
                }
            </LinearGradient>
        )
    }
    const PaperContents = () => {
        return (
            <>
                <Image source={calc} style={[calcParchement, calcUp]} />
                <Image source={calc} style={[calcParchement, calcDown]} />
                <Animated.View style={[parchementContent, animationOpacityStyle]}>{children}</Animated.View>
            </>
        )
    }
    const Paper = () => {
        return (
            <>
                <Image source={parchment} style={parchmentSize} />
                {showParchment && <PaperContents />}
            </>
        )
    }


    return (
        <Container>
            <Animated.View style={[{ position: "relative", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", }, animationHeightStyle]}>
                <Border />
                <Paper />
                <Border menu={true} />
            </Animated.View>
        </Container>

    )
}
export default Parchment;