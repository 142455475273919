// LIBRARY
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ImageBackground, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
// STYLE
import { useStyle } from '../../Contexts/style.context';
// PAGE
// COMPONENT
// SERVICE
// OTHER
import PopUp from '../../Components/Pop-up/popUp';
import { usePage } from '../../Contexts/page.context';
import { useAuth } from '../../Contexts/auth.context';
import { GetData, Login, UpdateLastConnexion } from '../../Services/Api/apiCall';
import { getStorage, setStorage } from '../../Services/Storage/storageCall';

export default function Signin({ setAuthPage }) {
    const { styles, isMobile } = useStyle();
    const { setAuth, userData, setUserData, setUserToken } = useAuth();
    const { submitPage } = usePage()
    const [username, setUsername] = useState(undefined);
    const [password, setPassword] = useState(undefined)
    const [dataLoad, setDataLoad] = useState(false);
    const { handleSubmit } = useForm();
    const [showPopUp, setShowPopUp] = useState();
    const [error, setError] = useState(null);

    useEffect(() => {
        if (userData?._id) {
            setUsername(userData?.username)
        }
    }, [])



    const verifyUser = async (value) => {
        const login = await Login("login", value);
        login.status === "ok" && await UpdateLastConnexion("users", login.user._id, getStorage("userToken"));
        login.message !== "valid login" && setError(login.message)
        login.message === "valid login" && (updateUserState(login.user), setStorage("userToken", login.token), setStorage("userData", login.user)
        )

    }
    const submitClicked = async () => {
        setShowPopUp(true);
        if (password !== undefined) await verifyUser({ username: username, password: password });
        if (password === undefined) {
            const userDataFetch = await GetData("users", userData._id, await getStorage("userToken"));
            setDataLoad(userDataFetch && true)
            const updateConnexion = await UpdateLastConnexion("users", userData._id, await getStorage("userToken"));
            updateUserState(userDataFetch);
        }
    }

    const updateUserState = (data) => {
        setTimeout(() => {
            setShowPopUp(false);
            setUserToken(true);
            setUserData(data);
            submitPage("Profil");
            setAuth(true);
        }, 1000);
    }
    const noStorageRender = () => {
        return (
            <>
                {!showPopUp ?
                    <View style={[styles.container, styles.acenter]} onSubmit={handleSubmit(submitClicked)}>
                        <TouchableOpacity style={[styles.rowContainer, styles.jcenter]}>
                            <Text style={[styles.title2, styles.textBold]} >S'identifier</Text>
                            <Text style={[styles.text2, styles.textBold, { paddingVertical: 20 }]} > / </Text>
                            <Text style={[styles.title2, styles.disable]} onPress={() => setAuthPage('Register')}>S'enregister</Text>
                        </TouchableOpacity>
                        <View style={styles.rowContainer}>
                            <Text style={styles.text}>Nom d'utilisateur : </Text>
                            <TextInput
                                style={styles.text}
                                placeholder="_ _ _ _ _ _ _ _ _ _"
                                onChangeText={username => setUsername(username)}
                                defaultValue={username !== undefined ? username : ""}
                            />
                        </View>
                        <View style={styles.rowContainer}>
                            <Text style={styles.text}>Mot de passe : </Text>
                            <TextInput
                                style={styles.text}
                                secureTextEntry={true}
                                placeholder="_ _ _ _ _ _ _ _ _ _"
                                onChangeText={password => setPassword(password)}
                            />
                        </View>
                        <View style={[styles.rowContainer, styles.acenter, styles.jcenter]}>
                            <Text style={[styles.text2, styles.titleShadow]}>{">>>"}</Text>
                            <TouchableOpacity style={styles.button} onPress={submitClicked}>
                                <Text style={[styles.text2, styles.textBold]}>Arkhanya me voila !</Text>
                            </TouchableOpacity>
                            <Text style={[styles.text2, styles.titleShadow]}>{"<<<"}</Text>
                        </View>
                    </View>
                    : error ?
                        <PopUp value={error} setDisplay={setShowPopUp} />
                        : <PopUp value={userData?.username ? `Bienvenu ${userData.username}` : "Chargement des données . . ."} setDisplay={null} />
                }
            </>
        )
    }
    const storageRender = () => {
        return (
            <>
                {!showPopUp ?
                    <View style={[styles.container, styles.acenter, styles.jcenter]} onSubmit={handleSubmit(submitClicked)}>
                        <View style={[styles.rowContainer, styles.acenter, styles.jcenter]}>
                            <Text style={styles.text}>Compte actuel : </Text>
                            <Text style={[styles.text2, styles.textBold, !isMobile && { width: 170 }]}>{userData?.username} </Text>
                        </View>
                        <View style={[styles.rowContainer, styles.acenter, styles.jcenter, { marginTop: 40 }]}>
                            <Text style={[styles.text2, styles.titleShadow]}>{">>>"}</Text>
                            <TouchableOpacity style={styles.button} onPress={submitClicked}>
                                <Text style={[styles.text2, styles.textBold]}>Arkhanya me voila !</Text>
                            </TouchableOpacity>
                            <Text style={[styles.text2, styles.titleShadow]}>{"<<<"}</Text>
                        </View>
                        <TouchableOpacity style={[styles.rowContainer, styles.jcenter, { marginTop: isMobile ? 80 : 100 }]} onPress={() => setUsername(undefined)}>
                            <Text style={[styles.text, styles.titleShadow]} >{">>>"} Changer de Compte {"<<<"}</Text>
                        </TouchableOpacity>
                    </View>
                    : error ?
                        <PopUp value={error} setDisplay={setShowPopUp} />
                        : <PopUp value={dataLoad ? `Bienvenu ${userData.username}` : "Chargement des données . . ."} setDisplay={null} />
                }
            </>
        )
    }


    return (
        <View style={{ height: isMobile ? 400 : 350, position: "relative", marginTop: isMobile ? 250 : 100 }}>
            {userData ? storageRender() : noStorageRender()}
        </View>
    )
}